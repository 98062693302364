import React from "react"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import styled from "styled-components"

const H1 = styled.h1`
  display:inline;
`
const TextSlider = ({ items = [] }) => {
  const settings = {
    autoplay: true,
    vertical: true,
    dots: false,
    arrows: false,
    infinite: true,
    speed: 250,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 1250,
  }

  return (
    <Slider {...settings}>
      {items.map((item, i) => (
        <div key={i}><H1>{item}</H1></div>
      ))}
    </Slider>
  )
}

export default TextSlider
