import React from "react"
import styled from "styled-components"

import HomeBanner from "../components/home-banner"
import HomeProduct from "../components/home-product"
import HomeStaff from "../components/home-staff"
import HomeProjects from "../components/home-projects"
import HomeTechLovers from "../components/home-tech-lovers"
import Layout from "../components/layout"
import IntlText from "../components/intl-text"
import { title2_Emphasis, title1_Emphasis } from "../components/global-styles"
import { breakpoints } from "../theme"
import { graphql, useStaticQuery } from "gatsby";

const Headline = styled.h2`
  ${title2_Emphasis};
  margin-bottom: 51px;
  @media (min-width: ${breakpoints.medium}) {
    ${title1_Emphasis}
  }`

const Index = () => {
  const containerImage = useStaticQuery(graphql`
  query generalImage {
    dark: file(base: {eq: "home-banner-dark_2500x1562.png" }) {
      id
      base
      relativePath
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          formats: [AUTO,WEBP],
          width: 1000
        )
      }
    }

    light: file(base: {eq: "home-banner-light_2500x1562.png" }) {
    id
    base
    relativePath
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          formats: [AUTO,WEBP],
          width: 1000
        )
      }
    }

    imageProduct :allFile(filter: {base: {eq: "product-delivery.png"}}){
        edges {
          node {
            id
            base
            relativePath
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED,
                  formats: [AUTO,WEBP],
                  width:500
                )
              }
          }
        }
      }

      imageStaff :allFile(filter: {base: {eq: "staff-augmentation.png"}}){
        edges {
          node {
            id
            base
            relativePath
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED,
                  formats: [AUTO,WEBP],
                  width:500
                )
              }
          }
        }
      }
  }
`);
  return (
    <Layout
      pageTitle="Home"
      title="A tech-team of software developers at your fingertips - Sysgarage"
      description="We are a team of software developers who can join your business and produce value for you. Come meet us!"
    >
      <HomeBanner darkImage={containerImage.dark} lightImage={containerImage.light} />
      <div className="container">
        <div className="d-lg-block row">
          <div className="col-12">
            <Headline>
              <IntlText id="pages.home.app_development_services.title" />
            </Headline>
          </div>
        </div>
      </div>
      <HomeProduct imageProduct={containerImage.imageProduct} />
      <HomeStaff imageStaff={containerImage.imageStaff} />
      <HomeProjects />
      <HomeTechLovers />
    </Layout>
  )
}

export default Index
