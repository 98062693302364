import React from "react"
import styled, { withTheme } from "styled-components"
import { headline1, title1_Emphasis } from "../global-styles"
import TextSlider from "../text-slider"
import { breakpoints } from "../../theme"
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import "./styles.scss"
import { BANNER_ALT } from "../../constants/Constants"

const Wrapper = styled.div`
  height: calc(100vh - 56px);
  overflow: hidden;
  @media (min-width: ${breakpoints.medium}) {
    height: 600px;
  }
  @media (min-width: ${breakpoints.large}) {
    margin-bottom: 70px;
  }
  @media (min-width: ${breakpoints.extralarge}) {
    height: calc(100vh - 90px);
    overflow: visible;
  }
`
const Content = styled.div`
  position: relative;
  margin-top: 56px;
  @media (min-width: ${breakpoints.large}) {
    margin-top: 40px;
  }
`

const SliderItem = styled.h2`
  ${title1_Emphasis};
  display: inline;
  font-weight: 600 !important;
  @media (min-width: ${breakpoints.medium}) {
    ${headline1};
  }
`

const SliderContainer = styled.div`
  margin-top: 15px;
  z-index: 4;
`

const HeadLine = styled.h1`
  ${title1_Emphasis};
  color: ${props => props.theme.text};
  &::after {
    content: "Product Engineers";
  }

  @media (min-width: ${breakpoints.medium}) {
    ${headline1};
    &::after {
      content: "A tech-team at your fingertips";
    }
  }

  @media (min-width: ${breakpoints.large}) {
    margin-top: 100px;
  }
`

const technologies = ["Javascript", "ReactJS", "NodeJS", "React Native"]
const sliderItems = technologies.map(item => (
  <SliderItem className="sg-text-gradient">{item}</SliderItem>
))

const HomeBanner = ({ theme, darkImage, lightImage }) => {
  return (
    <Wrapper>
      <Content className="container">
        <div className="row">
          <div
            className="col-10 col-md-8 col-lg-7 d-lg-flex flex-lg-column justify-content-lg-start"
            style={{ zIndex: 4 }}
          >
            <HeadLine />
            <SliderContainer>
              <TextSlider items={sliderItems} />
            </SliderContainer>
          </div>
          <div className="col-1 col-md-4 col-lg-5 d-lg-flex justify-content-lg-center">
            <GatsbyImage
              className="image-banner-container"
              image={getImage(theme.isDark ? darkImage : lightImage)}
              alt={BANNER_ALT} />
          </div>
        </div>
      </Content>
    </Wrapper>
  )
}

export default withTheme(HomeBanner)
