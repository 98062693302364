import React from "react"
import styled from "styled-components"
import { body1_Emphasis, title1_Emphasis, body1 } from "./global-styles"
import IntlText from "./intl-text"
import { breakpoints, colors } from "../theme"

const nodejs = require("src/images/technologies/nodejs.svg")
const react = require("src/images/technologies/react.svg")
const gatsby = require("src/images/technologies/gatsby.svg")
const reactNative = require("src/images/technologies/react-native.svg")
const aws = require("src/images/technologies/aws.svg")
const nextjs = require("src/images/technologies/nextjs.svg")
const mysql = require("src/images/technologies/mysql.svg")
const mongodb = require("src/images/technologies/mongodb.svg")

const Wrapper = styled.div`
  margin-top: 50px;
  @media (min-width: ${breakpoints.medium}) {
    margin-top: 80px;
    display: flex;
    align-items: center;
  }
  @media (min-width: ${breakpoints.extralarge}) {
    margin-top: 120px;
  }
`
const Title = styled.h2`
  ${title1_Emphasis};
`
const Subtitle = styled.h3`
  ${body1_Emphasis};
  margin-top: 18px;
  margin-bottom: 18px;
  @media (min-width: ${breakpoints.medium}) {
    color: ${props => props.theme.homeSectionDescription};
  }
`
const Body = styled.p`
  ${body1};
  @media (min-width: ${breakpoints.medium}) {
    color: ${props => props.theme.homeSectionDescription};
  }
`
const TechnologiesContainer = styled.div`
  width: 276px;
  height: 322px;
  margin: 60px auto 0;
  position: relative;

  & .item {
    background-color: ${colors.white};
    border-radius: 50px;
    position: absolute;

    &.nodejs {
      padding: 10px 20px;
      top: 0;
      left: 29px;
      img {
        width: 47px;
      }
    }
    &.nextjs {
      padding: 6px 13px;
      top: 10px;
      right: 43px;
      img {
        width: 55px;
      }
    }
    &.react-native {
      padding: 20px 23px;
      top: 74px;
      left: 15px;
      img {
        width: 110px;
      }
    }
    &.aws {
      padding: 10px 18px;
      top: 89px;
      right: 0;
      img {
        width: 36px;
      }
    }
    &.gatsby {
      padding: 13px 12px;
      top: 169px;
      left: 0;
      img {
        width: 70px;
      }
    }
    &.react {
      padding: 17px 19px;
      top: 165px;
      right: 10px;
      img {
        width: 100px;
      }
    }
    &.mysql {
      padding: 12px 24px;
      bottom: 26px;
      left: 9px;
      img {
        width: 55px;
      }
    }
    &.mongodb {
      padding: 17px 13px;
      bottom: 0px;
      right: 20px;
      img {
        width: 70px;
      }
    }
  }

  @media (min-width: ${breakpoints.medium}) {
    width: 467px;
    height: 276px;
    margin: 0 auto;

    & .item {
      background-color: ${colors.white};
      border-radius: 50px;
      position: absolute;

      &.nodejs {
        padding: 11px 23px;
        top: 0;
        left: 198px;
        img {
          width: 58px;
        }
      }
      &.nextjs {
        padding: 10px 21px;
        top: 95px;
        left: 193px;
        right: auto;
        img {
          width: 70px;
        }
      }
      &.react-native {
        padding: 24px 28px;
        top: 37px;
        left: 0px;
        img {
          width: 130px;
        }
      }
      &.aws {
        padding: 11px 21px;
        top: 137px;
        left: 66px;
        right: auto;
        img {
          width: 42px;
        }
      }
      &.gatsby {
        padding: 15px;
        top: 43px;
        left: auto;
        right: 40px;
        img {
          width: 83px;
        }
      }
      &.react {
        padding: 20px 22px;
        top: 190px;
        right: 121px;
        img {
          width: 120px;
        }
      }
      &.mysql {
        padding: 14px 28px;
        bottom: 90px;
        left: auto;
        right: 0;
        img {
          width: 65px;
        }
      }
      &.mongodb {
        padding: 20px 16px;
        bottom: 0px;
        right: auto;
        left: 32px;
        img {
          width: 83px;
        }
      }
    }
  }
`

const HomeTechLovers = () => {
  return (
    <Wrapper className="container d-flex align-items-center">
      <div className="row">
        <div className="col-12 col-lg-6 d-flex flex-column justify-content-center">
          <Title>
            <IntlText id="pages.home.tech_lovers.title" />
          </Title>
          <Subtitle>
            <IntlText id="pages.home.tech_lovers.subtitle" />
          </Subtitle>
          <Body>
            <IntlText id="pages.home.tech_lovers.body" />
          </Body>
        </div>
        <div className="col-12 d-flex justify-content-center align-items-center col-lg-6">
          <TechnologiesContainer className="wow fadeIn">
            <div className="item nodejs">
              <img src={nodejs} alt="NodeJS" />
            </div>
            <div className="item nextjs">
              <img src={nextjs} alt="NextJS" />
            </div>
            <div className="item react-native">
              <img src={reactNative} alt="React Native" />
            </div>
            <div className="item aws">
              <img src={aws} alt="Amazon Web Services" />
            </div>
            <div className="item gatsby">
              <img src={gatsby} alt="Gatsby Framework" />
            </div>
            <div className="item react">
              <img src={react} alt="React" />
            </div>
            <div className="item mysql">
              <img src={mysql} alt="MySQL" />
            </div>
            <div className="item mongodb">
              <img src={mongodb} alt="Mongo DB" />
            </div>
          </TechnologiesContainer>
        </div>
      </div>
    </Wrapper>
  )
}

export default HomeTechLovers
