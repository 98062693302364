import React from "react"
import styled, { withTheme } from "styled-components"

import HomeSectionDescription from "../home-section-description"
import { breakpoints } from "../../theme"
import IntlText from "../intl-text"
import { STAFF_AUGMENTATION_ALT } from "../../constants/Constants"
import { SERVICE_STAFF_AUGMENTATION_PAGE } from "../../navigation"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "./styles.scss";

const Wrapper = styled.div`
  margin-top: 93px;
  @media (min-width: ${breakpoints.medium}) {
    margin-top: 140px;
  }
  @media (min-width: ${breakpoints.large}) {
    margin-top: 50px;
  }
  @media (min-width: ${breakpoints.extralarge}) {
    margin-top: 0;
  }
`

const HomeStaff = ({ theme, imageStaff }) => {
  const filterImage = imageStaff.edges.filter((i) => i.node.relativePath === theme.homeStaffImage)

  return (
    <Wrapper>
      <div className="container">
        <div className="row h-100">
          <div className="d-flex justify-content-center align-items-center col-12 col-md-6 order-md-2">
            <GatsbyImage className="image-staff-container" image={getImage(filterImage[0].node.childImageSharp)} alt={STAFF_AUGMENTATION_ALT} />
          </div>
          <div className="col-12 col-md-6 d-flex flex-column justify-content-center order-md-1">
            <HomeSectionDescription
              title={
                <IntlText id="pages.home.app_development_services.staff_augmentation.title" />
              }
              body={
                <IntlText id="pages.home.app_development_services.staff_augmentation.body" />
              }
              chipColor="#FF6463"
              chipText={
                <IntlText id="pages.home.app_development_services.staff_augmentation.label" />
              }
              buttonText={
                <IntlText id="pages.home.app_development_services.staff_augmentation.button" />
              }
              linkHref={SERVICE_STAFF_AUGMENTATION_PAGE}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default withTheme(HomeStaff)
