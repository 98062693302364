import React from "react"
import styled, { withTheme } from "styled-components"
import { title1 } from "./global-styles"
import { breakpoints } from "../theme"
import { Link } from "gatsby"
import Button from "./button"

const Title = styled.h3`
  ${title1};
  margin-top: 38px;
`
const Description = styled.p`
  color: ${props => props.color};
  margin-top: 32px;
  @media (min-width: ${breakpoints.medium}) {
    color: ${props => props.theme.homeSectionDescription};
  }
`
const Label = styled.h2`
  padding: 3px 10px;
  background-color: ${props => props.chipColor};
  color: ${props => props.theme.homeSectionChipText};
  display: inline-block;
  border-radius: 2px;
  text-transform: uppercase;
  @media (min-width: ${breakpoints.extralarge}) {
    font-weight: 500;
  }
`

const HomeSectionDescription = ({
  chipText,
  chipColor,
  title,
  body,
  buttonText,
  theme,
  linkHref,
}) => {
  return (
    <div>
      <Link to={linkHref}>
        <Label chipColor={chipColor} className="caption">
          {chipText}
        </Label>
      </Link>
      <Title>{title}</Title>
      <Description className="body1" color={theme.homeSectionDescription}>
        {body}
      </Description>
      <div style={{ marginTop: "51px" }}>
        <Link to={linkHref}>
          <Button
            variant="outlined"
            size="big"
            bgColor={theme.body}
            color={theme.homeSectionButton}
          >
            {buttonText}
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default withTheme(HomeSectionDescription)
