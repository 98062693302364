import React from "react"
import styled, { withTheme } from "styled-components"
import HomeSectionDescription from "../home-section-description"
import IntlText from "../intl-text";
import { breakpoints } from "../../theme";
import { PRODUCT_DELIVERY_TEAM_ALT } from "../../constants/Constants";
import { SERVICE_PRODUCT_TEAM_PAGE } from "../../navigation";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import "./styles.scss"

const Wrapper = styled.div`
  overflow-x: hidden;
  @media (min-width: ${breakpoints.medium}) {
    padding-top: 70px;
    margin-bottom: 150px;
  }
`
const HomeProduct = ({ theme, imageProduct }) => {

  const filterImage = imageProduct.edges.filter((i) => i.node.relativePath === theme.homeProductImage)

  return (
    <Wrapper className="container d-flex align-items-center">
      <div className="row">
        <div className="col-12 col-md-6 d-flex justify-content-center d-md-flex align-items-md-center justify-content-md-end p-6">
            <GatsbyImage
              className="image-product-container"
              image={getImage(filterImage[0].node.childImageSharp)}
              alt={PRODUCT_DELIVERY_TEAM_ALT} />
        </div>
        <div className="col-12 col-md-6">
          <HomeSectionDescription
            title={
              <IntlText id="pages.home.app_development_services.product_team.title" />
            }
            body={
              <IntlText id="pages.home.app_development_services.product_team.body" />
            }
            chipColor="#FF69A5"
            chipText={
              <IntlText id="pages.home.app_development_services.product_team.label" />
            }
            buttonText={
              <IntlText id="pages.home.app_development_services.product_team.button" />
            }
            linkHref={SERVICE_PRODUCT_TEAM_PAGE}
          />
        </div>
      </div>
    </Wrapper>
  )
}

export default withTheme(HomeProduct)
