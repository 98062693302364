import React from "react"
import styled from "styled-components"

import {
  title1_Emphasis,
  title1,
  title2,
  body1_Emphasis,
} from "./global-styles"
import IntlText from "./intl-text"

import { breakpoints } from "../theme"
import ProjectList from "./project-list"

const Title = styled.h2`
  ${title1};
  @media (min-width: ${breakpoints.extralarge}) {
    ${title1_Emphasis};
  }
`
const Wrapper = styled.div`
  margin-top: 150px;
`

const Subtitle = styled.p`
  ${body1_Emphasis};
  margin-top: 23px;
  color: ${props => props.theme.text};
  @media (min-width: ${breakpoints.medium}) {
    margin-top: 36px;
    color: ${props => props.theme.homeSectionDescription};
    max-width: 900px;
  }
  @media (min-width: ${breakpoints.extralarge}) {
    ${title2};
  }
`
const ProjectsContainer = styled.div`
  margin-top: 39px;
  justify-content: center;
`

const HomeProjects = () => {
  return (
    <Wrapper className="container">
      <div className="row">
        <div className="col-12 col-md-6">
          <Title>
            <IntlText id="pages.home.latest_projects.title" />
          </Title>
          <Subtitle>
            <IntlText id="pages.home.latest_projects.subtitle" />
          </Subtitle>
        </div>
      </div>
      <ProjectsContainer>
        <ProjectList />
      </ProjectsContainer>
    </Wrapper>
  )
}

export default HomeProjects
